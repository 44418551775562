body,
html {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100%;

  @media (max-width: 450px) {
    max-width: 100%;
  }
}

body *::-webkit-scrollbar {
  width: 0.5em;
  background: #29252e;
}

body *::-webkit-scrollbar-thumb {
  background: #908796;
  height: 30px;
}

body *::-webkit-scrollbar-track-piece {
  display: none;
}

#root {
  width: 100%;
  height: 100%;
  display: flex;
}

* {
  box-sizing: border-box;
  overscroll-behavior: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
